<script>
    import { _ } from 'svelte-i18n'
    import { getCroppedImg } from '$utils/canvas.js'
    import Slider from '@bulatdashiev/svelte-slider'
    import Cropper from 'svelte-easy-crop'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import FullPageModal from '$lib/modal/FullPageModal.svelte'
    import MagnifyIcon from '$lib/icon/MagnifyIcon.svelte'
    import MinifyIcon from '$lib/icon/MinifyIcon.svelte'

    let {
        imgSrc,
        ajaxRequest = $bindable(false),
        type = 'picture',
        cancel = () => {},
        upload = () => {},
    } = $props()

    let value = $state([1, 2])
    let crop = $state({ x: 0, y: 0 })
    let zoom = $state(1)
    $effect(() => {
        zoom = value[0]
    })

    let croppedImage = $state()
    let pixelCrop
    async function cropImage() {
        croppedImage = await getCroppedImg(imgSrc, pixelCrop, type)
    }

    function previewCrop(e) {
        pixelCrop = e.pixels
    }
</script>

<FullPageModal close={cancel}>
    <div class="relative h-96 w-full">
        <Cropper
            image={imgSrc}
            bind:crop
            bind:zoom
            aspect={type == 'banner' ? 3 / 1 : 1}
            showGrid={false}
            oncropcomplete={previewCrop}
        />
    </div>

    <div class="flex w-full items-center">
        <MinifyIcon />
        <Slider bind:value min={1} max={2} step={0.1} />
        <MagnifyIcon />
    </div>

    <div class="mt-8 flex w-full items-center justify-between">
        <button onclick={cancel} class="w-24 hover:underline focus:underline"
            >{$_('Cancel')}</button
        >
        <button
            disabled={ajaxRequest}
            onclick={async () => {
                ajaxRequest = true
                await cropImage()
                upload(croppedImage)
            }}
            class="btn-background inline-flex h-9 w-32 items-center justify-center"
        >
            {#if ajaxRequest}
                <SpinnerIcon css="h-5 w-5" />
            {:else}
                {$_('OK')}
            {/if}
        </button>
    </div>
</FullPageModal>
