<script>
    import { _ } from 'svelte-i18n'
    import { validateOtp, preventDefault } from '$utils/common.js'
    import SvelteOtp from '@hellocoop/svelte-otp'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'

    let {
        verifyCode,
        otp = $bindable(''),
        ajaxRequestSendOTP = $bindable(false),
        ajaxRequestResendOTP = $bindable(false),
        resend = () => {},
    } = $props()

    $effect(() => {
        if (validateOtp(otp)) {
            verifyCode(otp)
        }
    })
</script>

<form class="text-center">
    <h1 class="text-lg">{$_('Enter verification code')}</h1>
    {#if !ajaxRequestSendOTP}
        <SvelteOtp
            bind:value={otp}
            autofocus={true}
            numOfInputs={6}
            wrapperClass="!gap-x-2 h-20 flex items-center justify-center"
            numberOnly={true}
            inputClass="text-lg !w-9 !h-11 !border-none"
        />

        <button
            onclick={preventDefault(() => {
                ajaxRequestResendOTP = true
                resend()
            })}
            class="inline-flex h-5 items-center justify-center text-sm font-medium opacity-80 hover:underline focus:underline"
        >
            {#if !ajaxRequestResendOTP}
                {$_('Resend verification code')}
            {:else}
                <SpinnerIcon css="h-5 w-5" />
            {/if}
        </button>
    {:else}
        <div class="mx-auto flex h-[6.5rem] items-center justify-center">
            <SpinnerIcon css="h-6 w-6 block mx-auto" />
        </div>
    {/if}
</form>
