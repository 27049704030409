<script>
    import { fly, fade } from 'svelte/transition'
    import { SLIDE_ANIM_MS } from '$src/constants.js'

    let {
        dataTest = '',
        position = 'center',
        children,
        css = '',
        close = () => {},
    } = $props()

    const handleEscKeyClose = (e) => {
        if (e.key === 'Escape') {
            close()
        }
    }
</script>

<svelte:window onkeydown={handleEscKeyClose} />

<div
    data-test={dataTest}
    id="modal"
    class="pointer {position === 'right'
        ? 'right'
        : position === 'left'
          ? 'left'
          : 'center'} modal absolute top-12 w-full max-w-sm rounded-md p-3 {css}"
    style="z-index: 46;"
    transition:fly={{ y: 10, duration: SLIDE_ANIM_MS }}
>
    {@render children?.()}
</div>
<span
    onclick={close}
    class="overlay bg-opacity-60"
    style="z-index: 45;"
    aria-hidden="true"
    in:fade={{ duration: 300 }}
    out:fade={{ duration: 200 }}
></span>

<style>
    .pointer::before {
        display: block;
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        top: -12px;
    }

    @media (prefers-color-scheme: dark) {
        .pointer::before {
            border-bottom: 12px solid #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .pointer::before {
            border-bottom: 12px solid white;
        }
    }

    .center {
        left: 50%;
        transform: translateX(-50%);
    }

    .center::before {
        left: 50%;
        transform: translateX(-50%);
    }

    .right {
        right: 0;
    }

    .right::before {
        right: 20px;
    }
</style>
